<template>
    <div>
        <b-form-select v-model="selected"
                       :options="options"
                       :class="validateError != '' ? 'box-border-color' : ''"
                       @input="handleInput"
        />
        <span class="invalid-feedback-custom"
              v-if="validateError"
              v-html="validateError"/>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                default: null
            },
            validateError: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String,
                default: ''
            },
            valueType: {
                type: String,
                default: 'chr'
            }
        },
        data() {
            return {
                selected: null,
                nullValueText: '',
                options: [
                    {
                        value: null,
                        text: this.$t('select')
                    },
                    {
                        value: this.valueType == 'chr' ? 'a' : 1,
                        text: this.$t('active')
                    },
                    {
                        value: this.valueType == 'chr' ? 'p' : 0,
                        text: this.$t('passive')
                    }
                ]
            }
        },
        watch: {
            value: function(newValue) {
                this.selected = newValue
            }
        },
        created() {
            this.selected = this.value
            if (this.placeholder) {
                this.options = [
                    {
                        value: null,
                        text: this.placeholder
                    },
                    {
                        value: this.valueType == 'chr' ? 'a' : 1,
                        text: this.$t('active')
                    },
                    {
                        value: this.valueType == 'chr' ? 'p' : 1,
                        text: this.$t('passive')
                    }
                ]
            }
        },
        methods: {
            handleInput(event) {
                this.$emit('input', event)
            }
        }
    }
</script>
